<template>
    <div>
        <nav-bar :title="info.page_title" url="/"></nav-bar>
        <div class="content" v-html="info.page_content"></div>
    </div>
</template>

<script>
    import {
        common_page
    } from '@/api/common.js';
    export default {
        name: 'page',
        data() {
            return {
                info: {},
            }
        },
        created() {
            this.id = this.$route.query.id;
            this.get_data();
        },
        methods: {
            get_data() {
                common_page({ id: this.id }).then(res => {
                    if (res.code == 1) {
                        this.info = res.data;
                        document.title = res.data.page_title;
                    }
                });
            },
        }
    }
</script>

<style scoped>
    .content {
        background: #fff;
        padding: 10px;
        font-size: 14px;
    }
</style>